import {
  sessionNumberFormatter,
  sessionStatusFormatter,
  dateOrDateTimeFormatter,
} from '../../utils/formatters';

export const defaultFilterValues = {
  search: '',
  num: '',
  client_id: '',
  parking_zone_id: '',
  client_type: '',
  started_at_from: '',
  started_at_to: '',
  status: '',
  lpn: '',
  total: '',
};

export const updatePeriods = [
  { value: 0, name: 'Не обновлять', icon: 'update-period-0' },
  { value: 10, name: '10 секунд', icon: 'update-period-10' },
  { value: 30, name: '30 секунд', icon: 'update-period-30' },
  { value: 60, name: '1 минута', icon: 'update-period-60' },
  { value: 300, name: '5 минут', icon: 'update-period-300' },
];

export const defaultColumns = [
  {
    dataField: 'checkBox',
    text: 'Выбор строки',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-0 text-center column-relative column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'num',
    text: 'Номер сессии',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-1',
    formatter: sessionNumberFormatter,
    dataClassName: 'align-middle',
  },
  {
    dataField: 'client_id',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-2',
    hidden: true,
  },
  {
    dataField: 'parking_zone',
    text: 'Зона клиента',
    formatter: e => e && e.name,
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-3',
    hidden: true,
  },
  {
    dataField: 'client',
    text: 'Тип клиента',
    disableSort: true,
    formatter: e => e && e.type,
    dataClassName: 'align-middle',
    headerClassName: 'column-4',
    // formatter: clientTypeFormatter,
    hidden: true,
  },
  {
    dataField: 'started_at',
    text: 'Дата и время начала',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'ended_at',
    text: 'Дата и время окончания',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'status',
    text: 'Статус сессии',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-7',
    formatter: sessionStatusFormatter,
    hidden: true,
  },
  {
    dataField: 'ended_at',
    text: 'Последняя операция',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-8',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'lpn',
    text: 'Номер ГРЗ',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-9',
    hidden: true,
  },
  {
    dataField: 'total',
    text: 'Сумма оплаты, руб',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-10',
    hidden: true,
  },
];
