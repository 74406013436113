import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  visualFormatter,
  govermentNumberFormatter,
  HostNameAndStatusFormatter,
} from '../../../utils/formatters';
import { Icons, Button } from '../../../components/base/BootstrapComponents';
import Grid from '../../../components/base/Grid/Grid';
import TooltipWithPortal from '../../../components/base/Tooltip/TooltipWithPortal';
import { defaultColumns as eventLogColumn } from '../../EventLog/moks';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import HostHistoryPopUp from './HostHistoryPopUp';

export default function HostStatus(props) {
  const { hostList: item } = props;
  const [isPopup, setIsPopup] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="host-status">
      <div className="host-status-info ">
        <div className="host-status-box">
          <div className="host-status-box-panel">
            {HostNameAndStatusFormatter({ item })}
            <div className="host-status-box-control">
              <div className="control-button" onClick={() => setIsPopup(true)}>
                <Icons width={24} height={24} customIcon icon="history" className="button-icons item-icon" />
                История
              </div>
              <TooltipWithPortal
                showByClick
                content={
                  (
                    <div className="control-button command">
                      <Icons width={18} height={12} customIcon icon="vector" className="button-icons item-icon" />
                      Команды
                    </div>
                  )
                }
                classNamePortal="mpf-more-actions setting-host-status-history-portal"
                tooltipContent={(
                  <div className="hosts-more-wrapper">
                    <Button variant="link" className="w-100 hosts-more-button">
                      <div className="hosts-more-button-icon">
                        <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                      </div>
                      Перезапуск ПО
                    </Button>
                    <Button variant="link" className="w-100 hosts-more-button">
                      <div className="hosts-more-button-icon">
                        <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                      </div>
                      Перезагрузка модуля оплаты
                    </Button>
                    <Button variant="link" className="w-100 hosts-more-button">
                      <div className="hosts-more-button-icon">
                        <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                      </div>
                      Антизатор
                    </Button>
                    <Button variant="link" className="w-100 hosts-more-button">
                      <div className="hosts-more-button-icon">
                        <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                      </div>
                      dummymode
                    </Button>
                    <Button variant="link" className="w-100 hosts-more-button">
                      <div className="hosts-more-button-icon">
                        <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                      </div>
                      Заблокировать
                    </Button>
                    <Button variant="link" className="w-100 hosts-more-button">
                      <div className="hosts-more-button-icon">
                        <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                      </div>
                      Перезагрузка модуля распознавания
                    </Button>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="host-status-items">
            <div className="host-status-item visual" key="visual">
              {visualFormatter((item.visual))}
            </div>
            {
              item.equipments.map((equipment, index) => (
                <div className="host-status-item" key={index}>
                  <div className="item-content" key={index}>
                    <span className="item-title">{equipment.nameRu}</span>
                    <div className="item-equipment-container">
                      <div className="equipments-group-item" key={equipment}>
                        <Icons width={32} height={32} customIcon icon={equipment.name} className="button-icons" />
                        <div className="equipments-group-item-status">
                          { equipment.status === 1 ? null : <Icons width={17} height={17} customIcon icon={equipment.status ? 'error-circle' : 'warning-circle'} className="button-icons" />}
                        </div>
                      </div>
                    </div>
                    { equipment.name === 'cam' ? govermentNumberFormatter(item.govermentNumber) : null }
                    <div className="item-description">
                      Текст описание, возможно подробное описание оборудования.
                    </div>
                  </div>
                  {/* { equipment.control.length ? (
                    <div className="item-control-panel">
                      {equipment.control.map((button, index) => (
                        <div key={index} className="item-control-button">
                          <Icons width={24} height={24} customIcon icon={button} className="button-icons" />
                        </div>
                      ))}
                    </div>
                  ) : null} */}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="host-status-event">
        <div className="host-status-event-panel">
          <div className="host-status-title">Журнал событий</div>
          <div className="host-status-show-table" onClick={() => navigate('/event-log')}>Показать всё</div>
        </div>
        <div className="host-table g-0 setting-table">
          <div className="frame">
            <div className="sticky-table-wrapper custom-scroll-theme">
              <Grid
                className="host-status-event-grid"
                columns={getColumnsForTable(eventLogColumn, ['talonNumber', 'client_id', 'date', 'sessionNumber', 'status', 'event'])}
                data={item.history}
              />
            </div>
          </div>
        </div>
      </div>
      { isPopup ? <HostHistoryPopUp item={item} onHide={() => { setIsPopup(false); }} /> : null}
    </div>
  );
}
