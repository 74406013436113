import React, { useState } from 'react';
import './zones-page.scss';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import GridZoneCards from './GridZoneCards';

export default function ZonesPage() {
  return (
    <div className="zones-page">
      <div className="zones-page-wrapper">
        <PageHeader header="Список зон" />
        <GridZoneCards />
      </div>
    </div>
  );
}
