import React from 'react';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import CustomButton from '../../../components/base/Button/CustomButton';

export default function ClientAddPopUp(props) {
  const { onHide, setMode } = props;

  return (
    <Dialog
      className={classNames('client-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <div className="hff-title-text f2">Новый клиент</div>
        )}
      />
      <DialogContent className="hff-content">
        Вы можете добавить нового клиента самостоятельно либо загрузить csv файл
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Добавить" onClick={() => { setMode('create'); onHide(); }} />
        <CustomButton isCancel text="Загрузить csv" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
