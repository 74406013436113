import React from 'react';
import { Icons } from '../base/BootstrapComponents';
import { visualIcon } from '../../modules/Hosts/components/moks';

export default function VisualPark(props) {
  const { type } = props;
  const item = visualIcon.find(e => e.value === type) || 0;

  return (
    <div className="visual-park">
      { item ? <Icons width={137} height={104} customIcon icon={item.icon} className="button-icons" /> : null }
    </div>
  );
}
