import React from 'react';
import {
  statusFormatter,
  historyStatusFormatter,
} from '../../../utils/formatters';
import { Icons } from '../../../components/base/BootstrapComponents';

export const tabOptions = [{ label: 'Таблица', value: 1 },
  { label: 'Схема', value: 2 }];

export const centerMap = [55.742963, 37.307106];

export const zoomMap = 18;

export const imgCoord = [[55.743526, 37.304802], [55.742126, 37.309789]];

export const defaultFilterValues = {
  query: '',
  name: '',
  status: null,
  search: '',
  zones: '',
  group: '',
  number: '',
  regNumber: '',
  typeClient: '',
  date: '',
  id: '',
};

export const defaultSettingColumnValues = {
  host: true,
  equipments: true,
  visual: true,
  govermentNumber: true,
  log: true,
  control: true,
  setting: true,
};

export const zonesOptions = [
  { value: 0, name: 'Зона клиента' },
  { value: 1, name: 'Общая зона' },
  { value: 2, name: 'VIP зона' },
  { value: 3, name: 'Зона перрона убытия' },
  { value: 4, name: 'Долговременная зона' },
  { value: 5, name: 'Зона перрона прибытия' }];

export const groupsOptions = [
  { value: 0, name: 'Группа хостов' },
  { value: 1, name: 'Группа 1' },
  { value: 2, name: 'Группа 2' },
  { value: 3, name: 'Группа 3' },
  { value: 4, name: 'Группа 4' },
  { value: 5, name: 'Группа 5' }];

export const updatePeriods = [
  { value: 0, name: 'Не обновлять', icon: 'update-period-0' },
  { value: 10, name: '10 секунд', icon: 'update-period-10' },
  { value: 30, name: '30 секунд', icon: 'update-period-30' },
  { value: 60, name: '1 минута', icon: 'update-period-60' },
  { value: 300, name: '5 минут', icon: 'update-period-300' },
];

export const visualIcon = [
  { value: 1, icon: 'radar-state-1' },
  { value: 2, icon: 'radar-state-2' },
  { value: 3, icon: 'radar-state-3' },
  { value: 4, icon: 'radar-state-4' },
  { value: 5, icon: 'radar-state-5' },
  { value: 6, icon: 'radar-state-6' },
];

export const hostStatusColumn = [
  {
    dataField: 'data',
    text: 'Дата и время',
    disableSort: true,
    hidden: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-0',
  },
  {
    dataField: 'command',
    text: 'Команда',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-1',
    hidden: true,
  },
  {
    dataField: 'status',
    text: 'Статус команды',
    disableSort: true,
    dataClassName: 'align-middle',
    formatter: historyStatusFormatter,
    headerClassName: 'column-2',
    hidden: true,
  },
];

export const hostList = [
  {
    id: '9ae87d32-4c52-416f-8ec0-40cb06c32105',
    type: 'Северная группа',
    host: 'Въездная группа 2',
    status: 1,
    variant: 2,
    setting: {},
    history: [
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
    ],
    govermentNumber: {
      type: 'military',
      number: '0734',
      char: '',
      charSecond: 'АО',
      region: 134,
      country: 'RUS',
    },
    control: [
      'unlock',
      'unlock-await',
      'lock',
    ],
    visual: {
      type: 1,
    },
    equipments: [
      {
        name: 'rfid',
        status: 1,
        nameRu: 'RFID-считыватель',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'qr',
        status: 1,
        nameRu: ' Считыватель QR-кода',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'microphone',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Интерком',
        control: [
          'phone',
          'video-cam',
          'power',
        ],
      },
      {
        name: 'coupon-printer',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Принтер талонов',
        control: [],
      },
      {
        name: 'paymant-module',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Модуль оплаты',
        control: [],
      },
      {
        name: 'cam',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Камера ГРЗ',
        control: [
          'picture',
          'pencile',
        ],
      },
      {
        name: 'recognition',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Сервис распознавания',
        control: [
          'reset',
          'power',
        ],
      },
      {
        name: 'barrier',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Шлагбаум',
        control: [
          'unlock',
          'unlock-await',
          'lock',
        ],
      },
    ],
    log: {
      date: '15:10',
      data: 'Автомобиль А 325 ЕЕ 750, сессия № 12565',
      amount: '600,00 руб',
    },
  },
  {
    id: '9ae87d32-4c52-416f-8ec0-40cb06c32105',
    type: 'Северная группа 12',
    host: 'Въездная группа 3',
    status: 1,
    variant: 2,
    setting: {},
    history: [
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
    ],
    govermentNumber: {
      type: 'diplomat',
      number: '002',
      char: '',
      charSecond: 'СД1',
      region: 134,
      country: 'RUS',
    },
    control: [
      'unlock',
      'unlock-await',
      'lock',
    ],
    visual: {
      type: 2,
    },
    equipments: [
      {
        name: 'rfid',
        status: 1,
        nameRu: 'RFID-считыватель',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'qr',
        status: 2,
        nameRu: ' Считыватель QR-кода',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'microphone',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Интерком',
        control: [
          'phone',
          'video-cam',
          'power',
        ],
      },
      {
        name: 'coupon-printer',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Принтер талонов',
        control: [],
      },
      {
        name: 'paymant-module',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Модуль оплаты',
        control: [],
      },
      {
        name: 'cam',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Камера ГРЗ',
        control: [
          'picture',
          'pencile',
        ],
      },
      {
        name: 'recognition',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Сервис распознавания',
        control: [
          'reset',
          'power',
        ],
      },
      {
        name: 'barrier',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Шлагбаум',
        control: [
          'unlock',
          'unlock-await',
          'lock',
        ],
      },
    ],
    log: {
      date: '15:10',
      data: 'Автомобиль А 325 ЕЕ 750, сессия № 12565',
      amount: '600,00 руб',
    },
  },
  {
    id: '9ae87d32-4c52-416f-8ec0-40cb06c32105',
    type: 'Северная группа 4',
    host: 'Въездная группа 1',
    status: 1,
    variant: 2,
    setting: {},
    history: [
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
    ],
    govermentNumber: {
      type: 'taxi',
      number: '976',
      char: 'АО',
      charSecond: '',
      region: 134,
      country: 'RUS',
    },
    control: [
      'unlock',
      'unlock-await',
      'lock',
    ],
    visual: {
      type: 3,
    },
    equipments: [
      {
        name: 'rfid',
        status: 2,
        nameRu: 'RFID-считыватель',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'qr',
        status: 1,
        nameRu: ' Считыватель QR-кода',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'microphone',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Интерком',
        control: [
          'phone',
          'video-cam',
          'power',
        ],
      },
      {
        name: 'coupon-printer',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Принтер талонов',
        control: [],
      },
      {
        name: 'paymant-module',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Модуль оплаты',
        control: [],
      },
      {
        name: 'cam',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Камера ГРЗ',
        control: [
          'picture',
          'pencile',
        ],
      },
      {
        name: 'recognition',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Сервис распознавания',
        control: [
          'reset',
          'power',
        ],
      },
      {
        name: 'barrier',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Шлагбаум',
        control: [
          'unlock',
          'unlock-await',
          'lock',
        ],
      },
    ],
    log: {
      date: '15:10',
      data: 'Автомобиль А 325 ЕЕ 750, сессия № 12565',
      amount: '600,00 руб',
    },
  },
  {
    id: '9ae87d32-4c52-416f-8ec0-40cb06c32105',
    type: 'Северная группа 12',
    host: 'Въездная группа 2',
    status: 1,
    variant: 2,
    setting: {},
    history: [
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
    ],
    govermentNumber: {
      type: 'default',
      number: '976',
      char: 'С',
      charSecond: 'МК',
      region: 134,
      country: 'RUS',
    },
    control: [
      'unlock',
      'unlock-await',
      'lock',
    ],
    visual: {
      type: 4,
    },
    equipments: [
      {
        name: 'rfid',
        status: 2,
        nameRu: 'RFID-считыватель',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'qr',
        status: 1,
        nameRu: ' Считыватель QR-кода',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'microphone',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Интерком',
        control: [
          'phone',
          'video-cam',
          'power',
        ],
      },
      {
        name: 'coupon-printer',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Принтер талонов',
        control: [],
      },
      {
        name: 'paymant-module',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Модуль оплаты',
        control: [],
      },
      {
        name: 'cam',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Камера ГРЗ',
        control: [
          'picture',
          'pencile',
        ],
      },
      {
        name: 'recognition',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Сервис распознавания',
        control: [
          'reset',
          'power',
        ],
      },
      {
        name: 'barrier',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Шлагбаум',
        control: [
          'unlock',
          'unlock-await',
          'lock',
        ],
      },
    ],
    log: {
      date: '15:10',
      data: 'Автомобиль А 325 ЕЕ 750, сессия № 12565',
      amount: '600,00 руб',
    },
  },
  {
    id: '9ae87d32-4c52-416f-8ec0-40cb06c32105',
    type: 'Северная группа 5',
    host: 'Въездная группа 11',
    status: 1,
    variant: 2,
    setting: {},
    history: [
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
    ],
    govermentNumber: {
      type: 'taxi',
      number: '976',
      char: 'АО',
      charSecond: '',
      region: 134,
      country: 'RUS',
    },
    control: [
      'unlock',
      'unlock-await',
      'lock',
    ],
    visual: {
      type: 2,
    },
    equipments: [
      {
        name: 'rfid',
        status: 1,
        nameRu: 'RFID-считыватель',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'qr',
        status: 0,
        nameRu: ' Считыватель QR-кода',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'microphone',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Интерком',
        control: [
          'phone',
          'video-cam',
          'power',
        ],
      },
      {
        name: 'coupon-printer',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Принтер талонов',
        control: [],
      },
      {
        name: 'paymant-module',
        status: 1,
        model: 'новая модель 1',
        nameRu: 'Модуль оплаты',
        control: [],
      },
      {
        name: 'cam',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Камера ГРЗ',
        control: [
          'picture',
          'pencile',
        ],
      },
      {
        name: 'recognition',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Сервис распознавания',
        control: [
          'reset',
          'power',
        ],
      },
      {
        name: 'barrier',
        status: 0,
        model: 'новая модель 1',
        nameRu: 'Шлагбаум',
        control: [
          'unlock',
          'unlock-await',
          'lock',
        ],
      },
    ],
    log: {
      date: '15:10',
      data: 'Автомобиль А 325 ЕЕ 750, сессия № 12565',
      amount: '600,00 руб',
    },
  },
  {
    id: '9ae87d32-4c52-416f-8ec0-40cb06c32105',
    type: 'Северная группа 7',
    host: 'Въездная группа 34',
    status: 1,
    variant: 2,
    setting: {},
    history: [
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        command: 'Перезагрузка ПО',
        sessionNumber: '123214',
        status: 1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: -1,
        event: 'Нет сигнала от RFID-считывателя',
      },
      {
        data: '09:34',
        client_id: '123234325',
        talonNumber: '123213',
        sessionNumber: '123214',
        command: 'Перезагрузка ПО',
        status: 0,
        event: 'Нет сигнала от RFID-считывателя',
      },
    ],
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    control: [
      'unlock',
      'unlock-await',
      'lock',
    ],
    visual: {
      type: 4,
    },
    equipments: [
      {
        name: 'rfid',
        status: 2,
        nameRu: 'RFID-считыватель',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'qr',
        status: 2,
        nameRu: ' Считыватель QR-кода',
        model: 'новая модель 1',
        control: [],
      },
      {
        name: 'microphone',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Интерком',
        control: [
          'phone',
          'video-cam',
          'power',
        ],
      },
      {
        name: 'coupon-printer',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Принтер талонов',
        control: [],
      },
      {
        name: 'paymant-module',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Модуль оплаты',
        control: [],
      },
      {
        name: 'cam',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Камера ГРЗ',
        control: [
          'picture',
          'pencile',
        ],
      },
      {
        name: 'recognition',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Сервис распознавания',
        control: [
          'reset',
          'power',
        ],
      },
      {
        name: 'barrier',
        status: 2,
        model: 'новая модель 1',
        nameRu: 'Шлагбаум',
        control: [
          'unlock',
          'unlock-await',
          'lock',
        ],
      },
    ],
    log: {
      date: '15:10',
      data: 'Автомобиль А 325 ЕЕ 750, сессия № 12565',
      amount: '600,00 руб',
    },
  },
];
