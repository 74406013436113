import React from 'react';
import classNames from 'classnames';
import './event-log-page.scss';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import CustomButton from '../../../components/base/Button/CustomButton';
import { Icons } from '../../../components/base/BootstrapComponents';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import TextAreaWithLabel from '../../../components/fields/TextInput/TextAreaWithLabel';
import { statusNotificationFormatter, dateTimeFormatter } from '../../../utils/formatters';

export default function EventLogPopUp(props) {
  const { onHide, selectedItem } = props;

  return (
    <Dialog
      className={classNames('elp-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            {/* Требуются уточнить у дизайнера */}
            <div className="hff-title-text f2">Информация о событии</div>
            {statusNotificationFormatter(selectedItem.status)}
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content custom-scroll-theme">
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'host',
            type: String,
            validators: [],
            labelKey: 'elp.hostName',
          }}
          inputProps={{
            value: selectedItem.host.name,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="middle"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'created_at',
            type: String,
            validators: [],
            labelKey: 'elp.date',
          }}
          inputProps={{
            value: dateTimeFormatter(selectedItem.created_at),
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'client_id',
            type: String,
            validators: [],
            labelKey: 'elp.client_id',
          }}
          inputProps={{
            value: selectedItem.client_id,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'ticket',
            type: String,
            validators: [],
            labelKey: 'elp.couponNumber',
          }}
          inputProps={{
            value: selectedItem.ticket,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'session',
            type: String,
            validators: [],
            labelKey: 'elp.sessionNumber',
          }}
          inputProps={{
            value: selectedItem.session.num,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          disabledResize
          component={TextAreaWithLabel}
          dtoObject={{
            name: 'message',
            type: String,
            validators: [],
            labelKey: 'elp.event',
          }}
          inputProps={{
            value: selectedItem.message,
          }}
        />
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Копировать" onClick={() => {}} />
        <CustomButton isCancel text="Закрыть" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
