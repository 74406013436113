import React from 'react';
import classNames from 'classnames';
import './goverment-number.scss';

export default function GovermentNumber(props) {
  const { govermentNumber } = props;

  return (
    <div className={classNames('gov-number', govermentNumber.type)}>
      <div className={classNames('gov-number-container', { long: govermentNumber.region.toString().length === 3 })}>
        <div className="gov-number-number">
          <div className="gov-number-number-container">
            { govermentNumber.char ? <div className="gov-number-number-char">  {govermentNumber.char} </div> : null }
            <div className="gov-number-number-int">  {govermentNumber.number} </div>
            { govermentNumber.charSecond && !govermentNumber.moto ? <div className="gov-number-number-char">  {govermentNumber.charSecond} </div> : null }
          </div>
        </div>
        <div className="gov-number-region">
          { govermentNumber.moto ? <div className="gov-number-number-char">  {govermentNumber.charSecond} </div> : null }
          <div className="gov-number-region-number">
            {govermentNumber.region}
            <div className="gov-number-region-container">
              <div className="gov-number-region-name">
                {govermentNumber.country}
              </div>
              <div className="gov-number-region-flag">
                <div className="strip" />
                <div className="strip" />
                <div className="strip" />
              </div>
            </div>
          </div>
          <div className="gov-number-region-container">
            <div className="gov-number-region-name">
              {govermentNumber.country}
            </div>
            <div className="gov-number-region-flag">
              <div className="strip" />
              <div className="strip" />
              <div className="strip" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
