import React, { useState } from 'react';
import Grid from './Grid';
import SettingColumn from '../SettingColumn/SettingColumn';
import { Icons } from '../BootstrapComponents';
import { useStoreState } from '../../../utils/utils';

export default function CustomizedGrid(props) {
  const {
    name,
    columns,
    settingsFormatter = a => a,
  } = props;

  const [isOpen, setOpen] = useState(false);

  const settingsColumn = {
    dataField: 'setting',
    text: <Icons onClick={() => setOpen(true)} width={24} height={24} customIcon icon="gear" className="button-icons" />,
    formatter: settingsFormatter,
    disableSort: true,
    headerClassName: 'settings-column',
    dataClassName: 'settings-column row-full-click',
    onClick: () => () => setOpen(true),
    // disablePosition: true,
    disableVisible: true,
  };

  const availableColumns = [...columns, settingsColumn].map((e, i) => ({ ...e, id: `${name}-column-${i}` }));
  const [visibleColumns, setVisibleColumns, resetVisible, saveVisible] = useStoreState(availableColumns.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {}), name);
  const [columnsOrder, setColumnsOrder, resetOrder, saveOrder] = useStoreState(availableColumns.map(c => c.id), `${name}-order`);
  return (
    <>
      <Grid {...props} columns={availableColumns.filter(c => visibleColumns[c.id]).sort((a, b) => (columnsOrder.indexOf(a.id) - columnsOrder.indexOf(b.id)))} />
      <SettingColumn
        open={isOpen}
        lockOnPopup
        availableColumns={availableColumns}
        setVisibleColumns={setVisibleColumns}
        visibleColumns={visibleColumns}
        setColumnsOrder={setColumnsOrder}
        columnsOrder={columnsOrder}
        resetVisible={resetVisible}
        resetOrder={resetOrder}
        saveVisible={saveVisible}
        saveOrder={saveOrder}
        onHide={() => { setOpen(false); }}
      />
    </>
  );
}
