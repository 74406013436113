import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { getClientData } from '../reducer';
import './client-page.scss';
import {
  defaultFilterValues, defaultColumns,
} from '../moks';
import {
  loadCliientList,
  deleteUser,
  updateUser,
  createUser,
} from '../actions';
import { responseLogWithNotification, getPaginationOpts } from '../../../utils/utils';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import { Icons } from '../../../components/base/BootstrapComponents';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import ClientLogGrid from './ClientGrid';
import ClientDetailsPopUp from './ClientDetailsPopUp';
import ClientLogFilterForm from './ClientFilterForm';
import SettingColumn from '../../../components/base/SettingColumn/SettingColumn';
import IconBox from '../../../components/common/IconBox';
import CustomButton from '../../../components/base/Button/CustomButton';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import ClientForm from './ClientForm';
import ClientAddPopUp from './ClientAddPopUp';
import ClientDeletePopUp from './ClientDeletePopUp';
import { showGlobalConfirm } from '../../System/actions';
import notificationDecorator from '../../Notifications/notificationDecorator';
import ClientStatus from './ClientStatus';

export default function ClientPage() {
  const dispatch = useDispatch();
  const [isPopup, setIsPopup] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState('');
  const [data, setData] = useState('');

  const [isOpenSettingColumn, setSettingColumn] = useState(false);
  const sessionLogColumn = getColumnsForTable(defaultColumns);
  const handlerClickRow = item => {
    setSelectedRow(item);
  };

  const loadDataFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('columns-filter'));
    return storage ? storage.map(column => {
      const search = sessionLogColumn.find(e => e.dataField === column.name);
      return { ...search, ...column };
    }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  };

  const {
    clientList,
    parkingZoneList,
    clientTypeList,
    clientListMeta,
  } = useSelector(getClientData);

  const removeClient = user_ids => event => {
    user_ids.map(id => dispatch(deleteUser(id)).then(() => {
      handleLoadingClientList();
      setSelected([]);
      notificationDecorator({
        title: 'Удаление клиента',
        message: 'Удаление клиента успешно',
      });
    }).catch(responseLogWithNotification));
  };

  const updateClient = data => {
    const action = mode === 'edit' ? updateUser({ id: data.id, userData: data }) : createUser({ userData: data });
    dispatch(action)
      .then(() => {
        notificationDecorator({
          title: 'Редактирование роли',
          message: 'Редактирование роли успешно',
        });
        loadCliientList();
      })
      .catch(responseLogWithNotification);
  };

  const [columns, setColumns] = useState(loadDataFromStorage());

  const zoneOptions = parkingZoneList.map(zone => ({ nameId: zone.name, value: zone.id }));
  const clientTypeOptions = clientTypeList.map(clientType => ({ nameId: clientType.label, value: clientType.value }));

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('client-filter')) || { ...defaultFilterValues },
  });

  const handleLoadingClientList = opts => {
    const filterData = getValues();
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(clientListMeta, opts);

    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };

    const action = loadCliientList;

    dispatch(loadCliientList(action(requestData))).catch(responseLogWithNotification);
  };

  watch();

  useEffect(() => {
    const subscription = watch(value => {
      handleLoadingClientList();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => handleLoadingClientList(), []);
  return (
    <div className="client-page">
      <div className="client-container">
        <div className="client-inputs-box">
          <div className={classNames('client-title-page', { variant: mode || data || selectedRow })}>
            {
              mode === 'create' || mode === 'edit' || selectedRow ? (
                <div className="path">
                  <span className="path-main" onClick={() => { setMode(null); setSelectedRow(null); setData(null); }}>Клиенты</span> / <span>{ selectedRow ? 'Просмотр карточки клиента' : mode === 'edit' ? 'Редактирование карточки клиента' : mode === 'create' ? 'Добавление нового клиента' : null}</span>
                </div>
              ) : null
            }
            <PageHeader header={selectedRow ? `Просмотр карточки клиента ${selectedRow.id} (ИД)` : mode === 'edit' ? `Редактирование карточки клиента ${data.id}` : mode === 'create' ? 'Добавление нового клиента' : 'Клиенты'} />
          </div>
          { !mode && !selectedRow ? (
            <div className="client-inputs">
              <FieldWithAttributeDTO
                inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                type="text"
                component={TextInput}
                dtoObject={{
                  name: 'search',
                  type: String,
                  validators: [],
                  labelKey: 'search',
                }}
                inputProps={{
                  ...register('search', { maxLength: 30 }),
                  value: getValues('search'),
                  onChange: e => {
                    setValue('search', e.target.value);
                    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
                  },
                }}
                className="small no-border search-field "
              />
              <FieldWithAttributeDTO
                type="text"
                component={TextInput}
                placeholder="ИД клиента"
                dtoObject={{
                  name: 'id',
                  type: String,
                  validators: [],
                  labelKey: 'client.client_id',
                }}
                inputProps={{
                  ...register('id', { maxLength: 30 }),
                  value: getValues('id'),
                  onChange: e => {
                    setValue('id', e.target.value);
                    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
                  },
                }}
                className="small no-border default-field"
              />
              <FieldWithAttributeDTO
                type="text"
                dtoObject={{
                  name: 'type',
                  type: String,
                  validators: [],
                  labelKey: 'client.typeClient',
                }}
                placeholder="Тип клиента"
                inputProps={{
                  ...register('type', { maxLength: 30 }),
                  onChange: e => {
                    setValue('type', e.target.value);
                    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
                  },
                  value: getValues('type') || '',
                }}
                component={CustomSelectInput}
                options={clientTypeOptions}
                className="select-field no-border"
              />
              <SeparateBox />
              <IconBox icon="export" />
              <SeparateBox />
              <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
              <SeparateBox />
              <IconBox icon="plus-add" onClick={() => setIsPopup(true)} />
              {selected.length ? <SeparateBox /> : null}
              {selected.length ? <CustomButton text="Удалить" onClick={() => setMode('delete')} icon="trash" /> : null}
              {isOpenFilter
                ? (
                  <ClientLogFilterForm
                    onHide={() => { setIsOpenFilter(false); }}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    reset={reset}
                    defaultValues={defaultFilterValues}
                    zoneOptions={zoneOptions}
                    clientTypeOptions={clientTypeOptions}
                  />
                )
                : null}
              {isOpenSettingColumn
                ? (
                  <SettingColumn
                    onHide={() => { setSettingColumn(false); }}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    reset={reset}
                    setColumns={setColumns}
                    columns={columns}
                    defaultValues={{}}
                  />
                )
                : null}
            </div>
          ) : (
            <div className="client-inputs">
              { mode !== 'create' && mode !== 'views' ? <IconBox icon="trash" onClick={() => { setMode('delete'); setSelected([data.id]); }} /> : null }
              <SeparateBox />
              <IconBox icon="lock-default" />
            </div>
          )}
        </div>
        { !selectedRow && (!mode || mode === 'views' || mode === 'delete') ? <ClientLogGrid setData={setData} removeClient={removeClient} meta={clientListMeta} handleLoadingClientList={handleLoadingClientList} selected={selected} selectedItem={selectedRow} setMode={setMode} setSelected={setSelected} data={clientList} onClickByRow={handlerClickRow} columns={defaultColumns.filter(e => e.hidden !== false)} setSettingColumn={setSettingColumn} /> : null }
        { mode === 'edit' || mode === 'create' ? <ClientForm updateClient={updateClient} clientTypeOptions={clientTypeOptions} zoneOptions={zoneOptions} mode={mode} selectedItem={data} setSelectedRow={setSelectedRow} setMode={setMode} /> : null }
        { data && (mode === 'views') ? <ClientDetailsPopUp setMode={setMode} onHide={() => setMode(false)} selectedItem={data} /> : null }
        { isPopup ? <ClientAddPopUp setMode={setMode} onHide={() => { setIsPopup(false); }} /> : null }
        { selectedRow && !mode ? <ClientStatus selectedItem={selectedRow} columns={defaultColumns} /> : null }
        { selected && selected.length && mode === 'delete' ? <ClientDeletePopUp setSelected={setSelected} removeClient={removeClient} user_ids={selected} onHide={() => setMode(false)} /> : null }
      </div>
    </div>
  );
}
