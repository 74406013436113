import React from 'react';
import classNames from 'classnames';
import './zones-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';

export default function ZoneList(props) {
  const {
    zones,
    onAdd,
    setSelectedZone,
    selectedZone,
  } = props;
  return (
    <div className="zone-card-list">
      <div className="zone-card-zones-header">
        <div className="zone-card-zones-title">Зоны</div>
        <div className="zone-card-zones-button" onClick={() => onAdd()}>
          <Icons customIcon className="search-icon" icon="plus" width={28} height={28} />
          <div>Добавить</div>
        </div>
      </div>
      <div className={classNames('zone-card-zones-container', { empty: !zones.length })}>
        { zones && zones.length
          ? zones.map(zone => (
            <div key={zone.id} className={classNames('zone-card-zones-item', { selected: selectedZone && selectedZone.id === zone.id })} onClick={() => setSelectedZone(zone)}>
              {zone.name}
            </div>
          )) : (
            <span>Добавьте зону</span>
          )}
      </div>
    </div>
  );
}
