import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import './payment-log-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import { getPaymentData } from '../reducer';
import { defaultFilterValues, defaultColumns } from '../moks';
import { loadPaymentList } from '../actions';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import PaymentLogGrid from './PaymentLogGrid';
import PaymentLogPopUp from './PaymentLogPopUp';
import PaymentLogFilterForm from './PaymentLogFilterForm';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import { responseLogWithNotification, getPaginationOpts } from '../../../utils/utils';

export default function PaymentLogPage() {
  const dispatch = useDispatch();
  const [isPopup, setIsPopup] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isOpenSettingColumn, setSettingColumn] = useState(false);
  const sessionLogColumn = getColumnsForTable(defaultColumns);
  const handlerClickRow = item => {
    setSelectedRow(item);
    setIsPopup(true);
  };

  const {
    paymentList,
    paymentListMeta,
    paymentTypeList,
    paymentMethodList,
    parkingZoneList,
  } = useSelector(getPaymentData);

  const typePaymentOptions = paymentTypeList.map(type => ({ name: type.label, value: type.value }));
  const methodPaymentOptions = paymentMethodList.map(type => ({ name: type.label, value: type.value }));
  const zoneOptions = parkingZoneList.map(zone => ({ name: zone.name, value: zone.id }));

  const loadDataFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('columns-filter'));
    return storage ? storage.map(column => {
      const search = sessionLogColumn.find(e => e.dataField === column.name);
      return { ...search, ...column };
    }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  };

  const [columns, setColumns] = useState(loadDataFromStorage());

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('plp-filter')) || { ...defaultFilterValues },
  });

  const handleLoadingPaymentList = opts => {
    const filterData = getValues();
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(paymentListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const action = loadPaymentList;
    dispatch(loadPaymentList(action(requestData))).catch(responseLogWithNotification);
  };

  watch();

  useEffect(() => {
    const subscription = watch(value => {
      handleLoadingPaymentList();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => handleLoadingPaymentList(), []);

  return (
    <div className="payment-log-page">
      <div className="plp-container">
        <div className="plp-inputs-box">
          <PageHeader header="Журнал платежей" />
          <div className="plp-inputs">
            <FieldWithAttributeDTO
              inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
              type="text"
              component={TextInput}
              dtoObject={{
                name: 'search',
                type: String,
                validators: [],
                labelKey: 'search',
              }}
              inputProps={{
                ...register('search', { maxLength: 30 }),
                value: getValues('search'),
                onChange: e => {
                  setValue('search', e.target.value);
                  localStorage.setItem('plp-filter', JSON.stringify({ ...getValues() }));
                },
              }}
              className="small no-border search-field "
            />
            <FieldWithAttributeDTO
              type="text"
              component={TextInput}
              placeholder="Номер сессии"
              dtoObject={{
                name: 'session_num',
                type: String,
                validators: [],
                labelKey: 'plp.session_num',
              }}
              inputProps={{
                ...register('session_num', { maxLength: 30 }),
                value: getValues('session_num'),
                onChange: e => {
                  setValue('session_num', e.target.value);
                  localStorage.setItem('plp-filter', JSON.stringify({ ...getValues() }));
                },
              }}
              className="small no-border"
            />
            <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'parking_zone_id',
                type: String,
                validators: [],
                labelKey: 'plp.clientZone',
              }}
              inputProps={{
                ...register('parking_zone_id', { maxLength: 30 }),
                onChange: e => {
                  setValue('parking_zone_id', e.target.value);
                  localStorage.setItem('plp-filter', JSON.stringify({ ...getValues() }));
                },
                value: getValues('parking_zone_id') || '',
              }}
              placeholder="Зона клиента"
              component={CustomSelectInput}
              options={zoneOptions}
              className="select-field no-border"
            />
            <SeparateBox />
            <IconBox icon="archive" />
            <SeparateBox />
            <IconBox icon="export" />
            <SeparateBox />
            <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
            {isOpenFilter
              ? (
                <PaymentLogFilterForm
                  onHide={() => { setIsOpenFilter(false); }}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  reset={reset}
                  paymentListMeta={paymentListMeta}
                  defaultValues={defaultFilterValues}
                  typePaymentOptions={typePaymentOptions}
                  methodPaymentOptions={methodPaymentOptions}
                />
              )
              : null}
          </div>
        </div>
        <PaymentLogGrid data={paymentList} meta={paymentListMeta} handleLoadingPaymentList={handleLoadingPaymentList} onClickByRow={handlerClickRow} columns={defaultColumns.filter(e => e.hidden !== false)} setSettingColumn={setSettingColumn} />
        { isPopup ? <PaymentLogPopUp onHide={() => setIsPopup(false)} selectedItem={selectedRow} columns={defaultColumns} /> : null }
      </div>
    </div>
  );
}
