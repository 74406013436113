import React from 'react';
import classNames from 'classnames';
import { zonesMock } from './moks';
import ZoneCard from './ZoneCard';

export default function GridZoneCards(props) {
  const {
    data = [...zonesMock],
  } = props;
  const count = zonesMock.length;

  return (
    <div className={classNames('grid-zones')}>
      {data.map((item, index) => <ZoneCard key={index} item={item} isMiniatures={count > 3} />)}
    </div>
  );
}
