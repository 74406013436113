import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Calendar } from 'react-multi-date-picker';
import moment from 'moment';
import Dropdown from '../../base/Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';
import gregorian_ru from '../RangeDatePicker/gregorian_ru';
import { Icons } from '../../base/BootstrapComponents';
import '../RangeDatePicker/RangeDatePickerStyles.scss';

const defaultDateFormat = 'DD.MM.YYYY';

export default function DatePicker(props) {
  const {
    className = '',
    controlId,
    disabled = false,
    inputProps = {},
    withLabel = false,
    usePortal = true,
    inline = false,
    fieldState = {
      error: '',
      touched: false,
    },
    disableInitialSelect = false,
    notDefaultValue = false,
    withFormat = null,
    variant = null,
  } = props;

  const { onChange = () => {}, name, value } = inputProps;
  const { error = '', touched = false } = fieldState;
  const pickerRef = useRef(null);
  const anchorElement = useRef(null);
  const [currentValue, setCurrent] = useState(value);
  const [format, setFormat] = useState(withFormat || defaultDateFormat);
  const [pickerKey, setPickerKey] = useState(0);

  useEffect(() => {
    setCurrent(value || new Date());
  }, []);

  const onSelect = (value, initialSelect) => {
    if (!inputProps) {
      return;
    }
    if (initialSelect && (disableInitialSelect || notDefaultValue)) {
      return;
    }
    const selected = value && value.isValid ? value.toDate().toString() : '';
    setCurrent(selected);
    onChange({ target: { value: selected, name } });
    setTimeout(() => {
      setPickerKey(pickerKey + 1);
    }, 50);
  };

  return (
    <div className={classNames('form-control-wrapper range-date-picker', { 'inline-picker': inline }, className)}>
      <div className="custom-react-date-picker">
        <Dropdown usePortal={usePortal}>
          <Dropdown.Toggle className="mini-profile-button">
            <div ref={anchorElement} className="date-selector-wrapper">
              <div className="date-selector">
                <TextInput
                  {...props}
                  readOnly
                  className={classNames('data-picker-text', { 'is-invalid': error && touched })}
                  containerRef={pickerRef}
                  inputProps={{
                    value: moment(currentValue).format(format),
                  }}
                  withLabel={withLabel}
                  inputAfter={(
                    <div className="search-wrapper">
                      <Icons customIcon className="search" width={18} height={20} icon="calendar" />
                    </div>
                  )}
                  variant={variant}
                />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu anchorElement={anchorElement} disableClickToggle className="data-picker-wrapper">
            <Calendar
              key={pickerKey}
              locale={gregorian_ru}
              weekStartDayIndex={1}
              onChange={onSelect}
              value={new Date(currentValue)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
