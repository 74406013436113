import React from 'react';
import classNames from 'classnames';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import { Icons } from '../../../components/base/BootstrapComponents';
import CustomButton from '../../../components/base/Button/CustomButton';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';

export default function ZonesEditPupUp(props) {
  const { item, onHide } = props;
  return (
    <Dialog
      className={classNames('zone-card-edit-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Редактирование зоны «Общая»</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={() => { onHide(); }} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content">
        <FieldWithAttributeDTO
          type="text"
          disabled
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'zones.total',
            type: String,
            validators: [],
            labelKey: 'zones.total',
          }}
          inputProps={{
            value: item.total,
          }}
        />
        {item.statuses.map(status => (
          <FieldWithAttributeDTO
            type="text"
            key={status.name}
            component={TextInputWithLabel}
            content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
            placeholder=""
            dtoObject={{
              name: status.name,
              type: String,
              validators: [],
              labelKey: status.name,
            }}
            inputProps={{
              value: status.total,
            }}
          />
        ))}
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Сохранить" onClick={() => {}} />
        <CustomButton isCancel text="Закрыть" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
