import React, { useState } from 'react';
import classNames from 'classnames';
import { Icons } from '../../../components/base/BootstrapComponents';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import YandexMap from '../../../components/base/Map/YandexMap';

export default function ConfigZonesAndHostAssignmentBox(props) {
  const {
    title = '',
    items = [],
    onAdd,
    onDelete,
    selectedZone = false,
    zones = [],
    setZones,
  } = props;

  return (
    <div className={classNames('czh-assignment-setting-box', { 'map-mode': selectedZone })}>
      { title ? <div className="czh-assignment-setting-title"> {title}</div> : null }
      {
        !selectedZone && items ? items.map(item => (
          <FieldWithAttributeDTO
            type="text"
            key={item}
            component={TextInput}
            placeholder="Укажите количество мест"
            dtoObject={{
              name: 'zones.totalSeat',
              type: String,
              validators: [],
              labelKey: 'zones.totalSeat',
            }}
            content={
              onDelete ? <Icons customIcon className="search-icon icon" icon="trash" width={24} height={24} /> : null
            }
            inputProps={{
              value: item.name,
            }}
          />
        )) : null
      }
      { onAdd
        ? (
          <div className="czh-assignment-create-host without-border">
            <Icons customIcon className="search-icon" icon="plus" width={28} height={28} />
            <div onClick={() => onAdd()}>Добавить</div>
          </div>
        ) : null }
      { selectedZone ? <YandexMap zones={zones} selectedZone={selectedZone} setZones={setZones} /> : null }
    </div>
  );
}
