import React from 'react';
import {
  MoreFormatter,
  statusFormatter,
  hostNameFormatter,
  dateOrDateTimeFormatter,
  eventFormatter,
} from '../../utils/formatters';

export const defaultFilterValues = {
  created_at: '',
  client_id: '',
  ticket: '',
  session: '',
  session_num: '',
  status: '',
  message: '',
};

export const defaultSettingColumnValues = {
  checkBox: true,
  hostName: true,
  date: true,
  client_id: true,
  couponNumber: true,
  sessionNumber: true,
  status: true,
  event: true,
  setting: true,
};

export const updatePeriods = [
  { value: 0, name: 'Не обновлять', icon: 'update-period-0' },
  { value: 10, name: '10 секунд', icon: 'update-period-10' },
  { value: 30, name: '30 секунд', icon: 'update-period-30' },
  { value: 60, name: '1 минута', icon: 'update-period-60' },
  { value: 300, name: '5 минут', icon: 'update-period-300' },
];

export const defaultColumns = [
  {
    dataField: 'checkBox',
    text: 'Выбор строки',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-0 text-center column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'host',
    id: 'host',
    text: 'Имя хоста (№)',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-1',
    formatter: e => hostNameFormatter(e.name),
    dataClassName: 'align-middle',
    disablePosition: false,
  },
  {
    dataField: 'created_at',
    text: 'Дата и время',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-2',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'client_id',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-3',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'ticket',
    text: 'Номер талона',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-4',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'session',
    text: 'Номер сессии',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    formatter: e => e.num,
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'status',
    text: 'Статус',
    disableSort: true,
    headerClassName: 'column-6',
    dataClassName: 'align-middle text-center',
    formatter: statusFormatter,
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'message',
    text: 'Событие',
    disableSort: true,
    formatter: eventFormatter,
    dataClassName: 'align-middle',
    headerClassName: 'column-7',
    hidden: true,
    disablePosition: false,
  },
];
