import Empty from '../../components/base/Empty';
import HostsPage from './components/HostsPage';

const routes = [
  {
    path: '/hosts-page/:page?',
    name: 'menu.hosts-page',
    icon: 'mon-host',
    customIcon: true,
    loginRequired: true,
    component: HostsPage,
    weight: 101,
    permission: ['frontend_menu_hosts'],
    children: [],
  },
];

export default routes;
