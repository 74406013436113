import React, { useState } from 'react';
import './czh-page.scss';
import classNames from 'classnames';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import { Icons } from '../../../components/base/BootstrapComponents';
import FileInput from '../../../components/fields/FileInput/FileInput';
import ConfigZonesAndHostAssignmentBox from './ConfigZonesAndHostAssignmentBox';
import ZoneList from '../../Zones/components/ZoneList';
import mapImgMock from '../../../theme/img/char-mock.png';

export default function ConfigZonesAndHost() {
  const [user, setUser] = useState({
    map: {
      centerMap: [55.742963, 37.307106],
      img: null,
    },
  });
  const [file, setFile] = useState();
  const [zones, setZones] = useState([
    {
      id: 123,
      name: 'Общая',
      hosts: [
        {
          name: 'Выездная группа 1',
        },
        {
          name: 'Выездная группа 2',
        },
        {
          name: 'Выездная группа 1',
        },
        {
          name: 'Выездная группа 2',
        },
      ],
      map: {
        imgCoord: [[55.743526, 37.304802], [55.742126, 37.309789]],
        centerMap: [55.742963, 37.307106],
        img: mapImgMock,
        geometry: null,
      },
    },
    {
      id: 12433,
      name: 'Новая зона',
      hosts: [
        {
          name: 'Хост 6',
        },
        {
          name: 'Хост 5',
        },
        {
          name: 'Хост 6',
        },
        {
          name: 'Хост 6',
        },
      ],
      map: {
        imgCoord: [[55.743526, 37.304802], [55.742126, 37.309789]],
        centerMap: [55.742963, 37.307106],
        img: mapImgMock,
        geometry: null,
      },
    },
    {
      id: 12334,
      name: 'Старая зона',
      hosts: [
        {
          name: 'Хост 45',
        },
        {
          name: 'Хост 56',
        },
        {
          name: 'Хост 56',
        },
        {
          name: 'Хост 76',
        },
      ],
      map: {
        imgCoord: [[55.743526, 37.304802], [55.742126, 37.309789]],
        centerMap: [55.742963, 37.307106],
        img: mapImgMock,
        geometry: null,
      },
    },
  ]);
  const [selectedZone, setSelectedZone] = useState(null);

  return (
    <div className="czh-page">
      <div className="czh-wrapper">
        <PageHeader header="Настройка зон и привязок к хостам" />
        { !user.map.img
          ? (
            <div className="czh-loader-container">
              { file
                ? (
                  <div className="czh-img-edit-container">
                    <img src={mapImgMock} alt="char" />
                    <div className="czh-box-buttons">
                      <div className="czh-edit-button" onClick={() => setUser({ map: { ...user.map, img: file } })}>Сохранить</div>
                      <div className="czh-edit-button save" onClick={() => setFile(null)}>Отменить</div>
                    </div>
                  </div>
                ) : (
                  <div className="czh-img-loader">
                    <Icons width={24} height={24} customIcon icon="loader-img" className="button-icons" />
                    <div className="czh-button-description">Для настройки зон и привязки их к хостам загрузите схему вашего парковочного пространства</div>
                    <FileInput
                      variant="button"
                      className="czh-button-loader"
                      label="Выбрать файл на устройстве"
                      input={{
                        onChange: () => setFile(mapImgMock),
                      }}
                    />
                  </div>
                )}
            </div>
          ) : (
            <div className="czh-assignment-container">
              <ZoneList zones={zones} onAdd={setZones} selectedZone={selectedZone} setSelectedZone={setSelectedZone} />
              <div className={classNames('czh-assignment', { empty: !selectedZone })}>
                <span className="czh-assignment-title">
                  Настройка зон и привязок к хостам
                </span>
                {selectedZone && zones.length
                  ? (
                    <>
                      <ConfigZonesAndHostAssignmentBox title="Название зоны" items={[{ name: selectedZone.name }]} />
                      <ConfigZonesAndHostAssignmentBox title="Название хоста" items={selectedZone.hosts} onAdd={() => {}} onDelete={() => {}} />
                      <ConfigZonesAndHostAssignmentBox
                        title="Схема парковки"
                        className="map"
                        selectedZone={selectedZone}
                        zones={zones}
                        setZones={setZones}
                      />
                    </>
                  ) : (
                    <div className="czh-assignment-empty">
                      Возможность настроить зону появится после добавления зоны
                    </div>
                  )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
