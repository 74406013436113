import React, { useState } from 'react';
import classNames from 'classnames';
import { Icons } from '../../../components/base/BootstrapComponents';
import { intlShape } from '../../../components/base/IntlProviderWrapper';
import ZonesEditPopUp from './ZonesEditPopUp';
import ZonesAddOptionPopUp from './ZonesAddOptionPopUp';

export default function ZoneCard(props) {
  const {
    item,
    isMiniatures,
    isDetail,
  } = props;
  const {
    formatMessage,
  } = intlShape;
  const [isEditPopUp, setIsEditPopUp] = useState(false);
  const [isAddPopUp, setIsAddPopUp] = useState(false);

  return (
    <div className={classNames('zone', { isMiniatures }, { isDetail })}>
      <div className="zone-title">
        {item.name}
        <div className="zone-title-control-button">
          <div className="zone-title-control-button-box" onClick={() => setIsEditPopUp(true)}>
            <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
          </div>
          <div className="zone-title-control-button-box" onClick={() => setIsAddPopUp(true)}>
            <Icons width={24} height={24} customIcon icon="squad" className="button-icons" />
          </div>
        </div>
      </div>
      <div className="zone-info">
        <div className="zone-info-statuses">
          {item.statuses.map(status => (
            <div className="status" key={status.name}>
              <div className="status-title">
                <Icons width={24} height={24} customIcon icon={status.icon} className="button-icons" />
                {formatMessage({ id: status.name })}
              </div>
              <span className="status-count">{status.total}</span>
            </div>
          ))}
        </div>
        <div className="separate" />
        <div className="zone_stat">
          <span className="zone_stat_title">
            {formatMessage({ id: 'zones.total' })}
          </span>
          <span className="zone-stat-count">
            {item.total}
          </span>
        </div>
      </div>
      { isEditPopUp ? <ZonesEditPopUp item={item} onHide={() => { setIsEditPopUp(false); }} /> : null}
      { isAddPopUp ? <ZonesAddOptionPopUp onHide={() => { setIsAddPopUp(false); }} /> : null }
    </div>
  );
}
